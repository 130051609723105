import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const useInvolveAsiaCampaign = () => {
    const router = useRouter();
    const [utmSource, setUtmSource] = useState(null);
    const [utmMedium, setUtmMedium] = useState(null);
    const [clickId, setClickId] = useState(null);

    useEffect(() => {
        if (router.isReady) {
            const { utm_source, utm_medium, click_id } = router.query;
            setUtmSource(utm_source || null);
            setUtmMedium(utm_medium || null);
            setClickId(click_id || null);
        }
    }, [router.isReady, router.query]);

    return { utmSource, utmMedium, clickId };
};

export default useInvolveAsiaCampaign;
